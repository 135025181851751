import React from 'react';
import cx from 'classnames';

import styles from './Macaw.module.scss';

function Macaw() {
  return (
    <section className={styles.container}>

      <div className={styles.intro}>
        <h1 className={styles.name}>Macaw</h1>
        <h2 className={styles.description}>Developing product strategy, design, and innovation for a billion dollar startup.</h2>
      </div>

    </section>
  );
}

export default Macaw;
