export const clamp = function(val, min, max) {
  if (val > max) return max;
  if (val < min) return min;
  return val;
}

export const degreesToRadians = function(deg) {
  return deg * (Math.PI / 180);
}

export const radiansToDegrees = function(rad) {
  return rad * (180 / Math.PI);
}

export const roundedRect = function(path, x, y, width, height, radius) {
  const point1 = { x: x + radius, y: y };
  const point2 = { x: x + width - radius, y: y };
  const point3 = { x: x + width, y: y + radius };
  const point4 = { x: x + width, y: y + height - radius };
  const point5 = { x: x + width - radius, y: y + height };
  const point6 = { x: x + radius, y: y + height };
  const point7 = { x: x, y: y + height - radius };
  const point8 = { x: x, y: y + radius };

  path.moveTo(point1.x, point1.y);
  path.lineTo(point2.x, point2.y);
  path.bezierCurveTo(
    point2.x + (radius / 2), point2.y,
    point3.x, point3.y - (radius / 2),
    point3.x, point3.y);
  path.lineTo(point4.x, point4.y);
  path.bezierCurveTo(
    point4.x, point4.y + (radius / 2),
    point5.x + (radius / 2), point5.y,
    point5.x, point5.y);
  path.lineTo(point6.x, point6.y);
  path.bezierCurveTo(
    point6.x - (radius / 2), point6.y,
    point7.x, point7.y + (radius / 2),
    point7.x, point7.y);
  path.lineTo(point8.x, point8.y);
  path.bezierCurveTo(
    point8.x, point8.y - (radius / 2),
    point1.x - (radius / 2), point1.y,
    point1.x, point1.y);
}
