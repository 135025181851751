import React, { useEffect } from 'react';
import cx from 'classnames';
import Tilter from './Tilter';

import Scene from './Scene';
import SocialBar from './SocialBar';
import Header from './Header';
import InVision from './InVision';
import Macaw from './Macaw';
import Tada from './Tada';
import Footer from './Footer';

import styles from './Home.module.scss';

function Home() {

  useEffect(() => {
    Tilter.init();
  });

  return (
    <div className={styles.container}>

      <div className={styles.content}>
        <SocialBar />
        <Header />
        <InVision />
        <Macaw />
        <Tada />
        <Footer />
      </div>

      <Scene />

    </div>
  );
}

export default Home;
