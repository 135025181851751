import React from 'react';

import styles from './Header.module.scss';

function Header() {
  return (
    <section className={styles.container}>

      <div className={styles.masthead}>
        <div className={styles.details}>
          <h1 className={styles.name}>Tom Giannattasio</h1>
          <h2 className={styles.title}>Product Design + Strategy</h2>
        </div>
      </div>

      <div className={styles.intro}>
        <div className={styles.mainBlurb}>I currently make design tools at <strong>InVision</strong>. I founded and built <strong>Macaw</strong>, which was acquired by InVision in 2015. I also worked at <strong>nclud</strong> designing products for <strong>Apple</strong> and <strong>Oracle</strong>. In 2012, I went to <strong>Twitter</strong> by way of an acquihire. I left shortly after to help <strong>MIT</strong> design and launch <strong>edX</strong>.</div>
      </div>

    </section>
  );
}

export default Header;
