import React from 'react';
import cx from 'classnames';

import styles from './Footer.module.scss';

function Footer() {
  return (
    <section className={styles.container}>

      Footer

    </section>
  );
}

export default Footer;
