class Dispatcher {

  constructor() {
    this._registered = {};
  }

  on = (type, callback, context) => {
    if (!this._registered[type]) {
      this._registered[type] = [];
    }

    this._registered[type].push({
      fn: callback,
      ctx: context
    });
  }

  off = (type, callback) => {
    var callbacks = this._registered[type];

    if (!callbacks) {
      return;
    }

    if (callback) {
      // eww, but ok
      for (var i = 0; i < callbacks.length; i++) {
        if (callbacks[i].fn === callback) {
          this._registered[type].splice(i, 1);
        }
      }
      // console.log(this._registered[type]);
    } else {
      this._registered[type] = undefined;
    }
  }

  dispatch = (type, data) => {
    var callbacks = this._registered[type];
    if (!callbacks) {
      return;
    }

    var eventData = data || {};
    eventData.type = type;

    for (var i = 0; i < callbacks.length; i++) {
      callbacks[i].fn.call(callbacks[i].ctx, eventData);
    }
  }

};

export default new Dispatcher();
