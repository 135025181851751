import React from 'react';
import cx from 'classnames';

import styles from './InVision.module.scss';

function InVision() {
  return (
    <section className={styles.container}>

      <div className={styles.details}>
        <div className={styles.intro}>
          <h1 className={styles.name}>InVision</h1>
          <h2 className={styles.description}>Bringing design to life with vector drawing, animation, and prototyping all in a single tool.</h2>
        </div>

        <ul className={styles.role}>
          <li>Product Vision + Strategy</li>
          <li>Design Leadership</li>
          <li>Prototyping</li>
        </ul>
      </div>

    </section>
  );
}

export default InVision;
