import Dispatcher from './Dispatcher';
import {
  clamp,
} from './utils';

const Tilter = {

  perX: 1,
  perY: 1,

  DEVICE_MULTIPLIER: 2,

  axisTable: {
    landscape: {
      x: 'gamma',
      y: 'beta',
      z: 'alpha'
    },
    portrait: {
      x: 'beta',
      y: 'gamma',
      z: 'alpha'
    }
  },

  init: function() {
    const handleMove = this.handleMove.bind(this);
    const updateOrientation = this.updateOrientation.bind(this);
    const handleTilt = this.handleTilt.bind(this);

    updateOrientation();

    document.addEventListener('mousemove', handleMove);
    window.addEventListener('orientationchange', updateOrientation);
    window.addEventListener('deviceorientation', handleTilt);
  },

  updateOrientation: function(e) {
    this.orientation = window.orientation === 0 ? 'portrait' : 'landscape';
  },

  handleTilt: function(e) {
    // pull proper angle based on orientation
    var xAxis = this.axisTable[this.orientation].x;
    var xAngle = e[xAxis];

    var yAxis = this.axisTable[this.orientation].y;
    var yAngle = e[yAxis];

    const perX = clamp(yAngle / 30, -1, 1) * this.DEVICE_MULTIPLIER;
    const perY = clamp((xAngle - 40) / 40, -1, 1) * this.DEVICE_MULTIPLIER;

    Dispatcher.dispatch('tilt', {
      perX,
      perY
    });
  },

  handleMove: function(e)  {
    var width = window.innerWidth / 2;
    var height = window.innerHeight / 2;
    this.perX = (e.clientX - width) / width;
    this.perY = (e.clientY - height) / height;

    Dispatcher.dispatch('tilt', {
      perX: this.perX,
      perY: this.perY
    });
  }

};

export default Tilter;
