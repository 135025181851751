import React from 'react';
import cx from 'classnames';

import styles from './Tada.module.scss';

function Tada() {
  return (
    <section className={styles.container}>

      <div className={styles.intro}>
        <h1 className={styles.name}>Tada</h1>
        <h2 className={styles.description}>Your daily productivity assistant.</h2>
      </div>

    </section>
  );
}

export default Tada;
