import React from 'react';

import styles from './SocialBar.module.scss';

function SocialBar() {
  return (
    <div className={styles.container}>
      <a className={styles.link} href="http://twitter.com/attasi" target="_blank">Twitter</a>
      <a className={styles.link} href="http://linkedin.com/in/attasi" target="_blank">LinkedIn</a>
      <a className={styles.link} href="http://github.com/thomasxiii" target="_blank">GitHub</a>
      <a className={styles.link} href="http://dribbble.com/attasi" target="_blank">Dribbble</a>
      <a className={styles.link} href="#">Email</a>
    </div>
  );
}

export default SocialBar;
