import React, {
  useEffect,
  useRef
} from 'react';
import { throttle } from 'throttle-debounce';
import Dispatcher from './Dispatcher';
import { TweenMax, Sine } from 'gsap/all';
import { clamp } from './utils';
import * as THREE from 'three';
import { BokehShader, BokehDepthShader } from './lib/BokehShader2.js';
import ProjectedMaterial, { project } from 'three-projected-material'
import portraitURL from './images/portrait-1.jpg';

import styles from './Scene.module.scss';

function Scene() {

  let camera;
  let scene;
  let renderer;
  let light;
  let spotLight;
  let materialDepth;
  let group;
  const meshes = [];
  const canvasWrapper = useRef(null);

  var postprocessing = { enabled: true };
  var shaderSettings = {
    rings: 2,
    samples: 4
  };

  const extrudeSettings = {
    amount: 1,
    bevelEnabled: false,
    // bevelEnabled: true,
    // bevelSegments: 4,
    steps: 8,
    // bevelSize: 0,
    // bevelThickness: 0
  };

  function onTilt(e) {
    meshes.forEach(mesh => {
      // if (mesh.spin) {
      //   TweenMax.to(mesh.rotation, 1.15, {
      //     [mesh.spin.axis]: Math.PI * e.perY * mesh.spin.value / 48,
      //     ease: Sine.easeOut,
      //   });
      // }

      TweenMax.to(group.rotation, 1.15, {
        y: e.perX * .1,
        ease: Sine.easeOut,
      });

      if (mesh.gravity) {
        TweenMax.to(mesh.position, 1.15, {
          z: e.perY * mesh.gravity / 6,
          ease: Sine.easeOut,
        });
      }
    });
  }

  function resize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const pixelRatio =  window.devicePixelRatio;

    // update pixel ratio if necessary
    if (renderer.getPixelRatio() !== pixelRatio) {
      renderer.setPixelRatio(pixelRatio)
    }

    // setup new size & update camera aspect if necessary
    renderer.setSize(width, height);
    if (camera.isPerspectiveCamera) {
      camera.aspect = width / height;
    }
    camera.updateProjectionMatrix();

    renderer.setSize(width, height);
  }

  // function createShards() {
  //   group = new THREE.Group();
  //   for (let i = 0; i < 100; i++) {
  //     const x = (i % 10) - 5;
  //     const y = Math.floor(i / 10) - 5;
  //     console.log(x, y);
  //     const mesh = createShard(x, y);
  //     // mesh.translateX(x);
  //     // mesh.translateY(y);
  //     // mesh.translateX((Math.random() - Math.random()) * 3);
  //     mesh.translateZ((Math.random() - Math.random()) * 2);
  //     const scale = 0.1;
  //     mesh.scale.set(scale, scale, scale);
  //     // mesh.rotation.x += Math.random() * Math.PI;
  //     // mesh.rotation.y += Math.random() * Math.PI;
  //     // mesh.rotation.z += Math.random() * Math.PI;
  //     group.add(mesh);
  //     meshes.push(mesh);
  //     project(mesh);
  //   }
  //   scene.add(group);
  // }

  function createShard(x, y) {
    const shard = new THREE.Shape();

    // const l = x - Math.random();
    // const r = x + Math.random();
    // const t = y - Math.random();
    // const b = y + Math.random();

    const l = x - 0.5;
    const r = x + 0.5;
    const t = y - 0.5;
    const b = y + 0.5;

    // const l = -0.5;
    // const r = 0.5;
    // const t = -0.5;
    // const b = 0.5;

    shard.moveTo(l, t);
    shard.lineTo(r, t);
    shard.lineTo(r, b);
    shard.lineTo(l, b);
    shard.lineTo(l, t);

    const geometry = new THREE.ExtrudeGeometry(shard, extrudeSettings);

    const material = new ProjectedMaterial({
      camera,
      texture: portraitTexture,
      color: 0x111111,
      cover: true,
    });

    const mesh = new THREE.Mesh(geometry, material);

    return mesh;
  }

  function createShards() {
    group = new THREE.Group();
    // group.rotation.y += .2;
    // group.rotation.x -= .5;

    for (let i = 0; i < 200; i++) {
      const x = (i % 20) - 10;
      const y = Math.floor(i / 20) - 5;
      const mesh = createShard(x, y);
      const scale = 0.05;
      mesh.scale.set(scale, scale, scale);
      mesh.rotation.z = Math.PI / 4;

      const object = new THREE.Object3D();

      const xSpin = Math.random();
      const ySpin = Math.random();
      const isXSpin = xSpin > ySpin;
      object.spin = {
        axis: isXSpin ? 'x' : 'y',
        value: isXSpin ? xSpin : ySpin,
      };
      object.gravity = Math.random() - 0.5;
      // object.origin = { ...mesh.position };
      object.origin = new THREE.Box3().setFromObject(mesh).getCenter();

      group.add(object);
      object.add(mesh);
      meshes.push(object);

      project(mesh);

      var box = new THREE.Box3().setFromObject(mesh);

      // box.getCenter(mesh.position);
      // mesh.position.multiplyScalar(-1);
      // var pivot = new THREE.Group();
      // group.add(pivot);
      // pivot.add(mesh);

      // object.translateX(x * scale);
      // object.translateY(y * scale);
      // mesh.geometry.center();
    }
    scene.add(group);
  }

  function createScene() {
    if (!isTextureLoaded || !isReactReady) {
      return;
    }

    camera = new THREE.PerspectiveCamera(45, 1, 0.01, 100);
    // camera = new THREE.PerspectiveCamera(45, 1, 0.01, 3)
    // camera.position.set(-1, 1.2, 1.5)
    // camera.lookAt(0, 0, 0)
    // camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.01, 100);
    // camera = new THREE.OrthographicCamera(45, window.innerWidth / window.innerHeight, 0.01, 100);
    camera.position.z = 0.5;
    camera.lookAt(0, 0, 0);

    camera.updateProjectionMatrix();

    scene = new THREE.Scene();
    // scene.background = new THREE.Color( 0xefd1b5 );
    // scene.fog = new THREE.FogExp2( 0xefd1b5, 0.0025 );
    scene.fog = new THREE.FogExp2(0x000000);

    var depthShader = BokehDepthShader;
    materialDepth = new THREE.ShaderMaterial( {
      uniforms: depthShader.uniforms,
      vertexShader: depthShader.vertexShader,
      fragmentShader: depthShader.fragmentShader
    } );
    materialDepth.uniforms[ 'mNear' ].value = camera.near;
    materialDepth.uniforms[ 'mFar' ].value = camera.far;

    // lights
    light = new THREE.AmbientLight(0xf5f6f9, 0.85);
    scene.add(light);

    spotLight = new THREE.SpotLight(0xffffff, 0.9, 0, 1, 1);
    spotLight.position.set(0, 200, 100);
    spotLight.castShadow = true;
    spotLight.shadow.mapSize.width = 1024 * 6;
    spotLight.shadow.mapSize.height = 1024 * 6;
    scene.add(spotLight);

    initPostprocessing();

    var effectController = {

      enabled: true,
      jsDepthCalculation: true,
      shaderFocus: false,

      fstop: 2.2,
      maxblur: 15.0,

      showFocus: false,
      focalDepth: 2.8,
      manualdof: true,
      vignetting: true,
      depthblur: true,

      threshold: 2.5,
      gain: 2.0,
      bias: 2.5,
      fringe: 2.7,

      focalLength: 35,
      noise: true,
      pentagon: false,

      dithering: 0.0001

    };

    var matChanger = function () {

      for ( var e in effectController ) {

        if ( e in postprocessing.bokeh_uniforms ) {

          postprocessing.bokeh_uniforms[ e ].value = effectController[ e ];

        }

      }

      postprocessing.enabled = effectController.enabled;
      postprocessing.bokeh_uniforms[ 'znear' ].value = camera.near;
      postprocessing.bokeh_uniforms[ 'zfar' ].value = camera.far;
      camera.setFocalLength( effectController.focalLength );

    };

    matChanger();

    renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      pixelRatio: window.devicePixelRatio,
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    canvasWrapper.current.appendChild(renderer.domElement);

    resize();

    createShards();

    window.addEventListener('resize', resize);
    window.addEventListener('mousewheel', updateScroll);
    window.addEventListener('scroll', updateScroll);
    window.addEventListener('touchmove', updateScroll);
    Dispatcher.on('tilt', onTilt);

    animate();
  }

  const updateScroll = throttle(10, (e) => {
    const newScroll = window.scrollY;

    const MAX_SCROLL = window.innerHeight * 1.2;
    const offset = clamp(1 - ((MAX_SCROLL - newScroll) / MAX_SCROLL), 0, 1);

    meshes.forEach(mesh => {
      const x = offset * 20 * Math.abs(mesh.gravity)
      const y = offset * 20 * Math.abs(mesh.gravity);
      const z = offset * -0.5 * Math.abs(mesh.gravity + 0.2);

      mesh.children[0].position.z = z;
    });

    spotLight.intensity = 1 - offset;
    light.intensity = 1 - offset;
  });

  let isTextureLoaded = false;
  let isReactReady = false;
  let portraitTexture;
  const loader = new THREE.TextureLoader();

  // load a resource
  loader.load(
    portraitURL,

    // onLoad callback
    function ( texture ) {
      isTextureLoaded = true;
      portraitTexture = texture;
      portraitTexture.anisotropy = 16;
      portraitTexture.wrapS = THREE.RepeatWrapping;
      portraitTexture.wrapT = THREE.RepeatWrapping;
      // portraitTexture.wrapS = THREE.ClampToEdgeWrapping;
      // portraitTexture.wrapT = THREE.ClampToEdgeWrapping;

      // // in this example we create the material when the texture is loaded
      // var material = new THREE.MeshBasicMaterial( {
      //   map: texture
      //  } );
      createScene();
    },

    // onProgress callback currently not supported
    undefined,

    // onError callback
    function ( err ) {
      console.error( 'An error happened.' );
    }
  );



  useEffect(() => {
    isReactReady = true;
    createScene();
  });

  function initPostprocessing() {

    postprocessing.scene = new THREE.Scene();

    postprocessing.camera = new THREE.OrthographicCamera( window.innerWidth / - 2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / - 2, - 10000, 10000 );
    postprocessing.camera.position.z = 100;

    postprocessing.scene.add( postprocessing.camera );

    var pars = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBFormat };
    postprocessing.rtTextureDepth = new THREE.WebGLRenderTarget( window.innerWidth, window.innerHeight, pars );
    postprocessing.rtTextureColor = new THREE.WebGLRenderTarget( window.innerWidth, window.innerHeight, pars );

    var bokeh_shader = BokehShader;

    postprocessing.bokeh_uniforms = THREE.UniformsUtils.clone( bokeh_shader.uniforms );

    postprocessing.bokeh_uniforms[ 'tColor' ].value = postprocessing.rtTextureColor.texture;
    postprocessing.bokeh_uniforms[ 'tDepth' ].value = postprocessing.rtTextureDepth.texture;
    postprocessing.bokeh_uniforms[ 'textureWidth' ].value = window.innerWidth;
    postprocessing.bokeh_uniforms[ 'textureHeight' ].value = window.innerHeight;

    postprocessing.materialBokeh = new THREE.ShaderMaterial( {

      uniforms: postprocessing.bokeh_uniforms,
      vertexShader: bokeh_shader.vertexShader,
      fragmentShader: bokeh_shader.fragmentShader,
      defines: {
        RINGS: shaderSettings.rings,
        SAMPLES: shaderSettings.samples
      }

    } );

    postprocessing.quad = new THREE.Mesh( new THREE.PlaneBufferGeometry( window.innerWidth, window.innerHeight ), postprocessing.materialBokeh );
    postprocessing.quad.position.z = - 500;
    postprocessing.scene.add( postprocessing.quad );

  }

  function shaderUpdate() {

    postprocessing.materialBokeh.defines.RINGS = shaderSettings.rings;
    postprocessing.materialBokeh.defines.SAMPLES = shaderSettings.samples;
    postprocessing.materialBokeh.needsUpdate = true;

  }

  function animate() {
    requestAnimationFrame( animate );

    for (let i = 0; i < meshes.length; i++) {
      const mesh = meshes[i];
      // mesh.translateZ(0.002 * Math.random());
      // mesh.rotation.x += 0.003;
      // mesh.rotation.y += 0.002;
    }

    renderer.render(scene, camera);
    // postRender();
  }

  function postRender() {
    renderer.clear();

    // render scene into texture
    renderer.setRenderTarget( postprocessing.rtTextureColor );
    renderer.clear();
    renderer.render( scene, camera );

    // render depth into texture
    scene.overrideMaterial = materialDepth;
    renderer.setRenderTarget( postprocessing.rtTextureDepth );
    renderer.clear();
    renderer.render( scene, camera );
    scene.overrideMaterial = null;

    // render bokeh composite
    renderer.setRenderTarget( null );
    renderer.render( postprocessing.scene, postprocessing.camera );
  }




  return (
    <div ref={canvasWrapper} className={styles.container}>

    </div>
  );
}

export default Scene;


